import { button, container, copyText, header, text, title, visual } from './header.module.scss'

import Bar from '../bar'
import Button from '../button'
import React from "react"
import TextRenderer from '../text/renderer'

const WebsiteHeader = ({ headline, copy, video, frontPage=false, buttonLabel, buttonLink }) => {
  return (
    <div className={header}>
      <div className={container}>
        <div className={text}>
          <h2 className={title}>{headline}</h2>
          <div className={copyText}><TextRenderer text={copy}/></div>
          { buttonLabel && buttonLink && (
            <div className={button}>
              <Button to={buttonLink}>{buttonLabel}</Button>
              <Bar/>
            </div>
          )}
        </div>
        {( video &&
          <div className={visual}>
            <video playsInline autoPlay loop muted>
              <source src={video}/>
            </video>
          </div>
        )}
      </div>
    </div>
  )
};

export default WebsiteHeader
