import { graphql, useStaticQuery } from "gatsby"

import Coach from '../components/coach'
import Instagram from '../components/instagram'
import Layout from '../layouts'
import PagePreview from '../components/page/preview'
import React from 'react'
import ReviewCarousel from '../components/reviews/carousel'
import Seo from '../components/seo'
import WebsiteHeader from '../components/website/header'
import video from '../images/hero.mp4'

const Index = () => {
  const { personalTraining, therapy, neuro, nutrition, davidWitton, reviews } = useStaticQuery(
    graphql`
      {
        personalTraining: imageSharp(resize: {originalName: {eq: "personal-training.jpg"}}) {
          gatsbyImageData(
            aspectRatio: 1
            outputPixelDensities: [0.25, 0.5, 1, 2, 3]
            quality: 75
            transformOptions: {
              fit: COVER
            }
            width: 539
          )
        }

        therapy: imageSharp(resize: {originalName: {eq: "schmerz-therapie.jpg"}}) {
          gatsbyImageData(
            aspectRatio: 1
            outputPixelDensities: [0.25, 0.5, 1, 2, 3]
            quality: 75
            transformOptions: {
              fit: COVER
            }
            width: 539
          )
        }

        neuro: imageSharp(resize: {originalName: {eq: "neuro-athletik.jpg"}}) {
          gatsbyImageData(
            aspectRatio: 1
            outputPixelDensities: [0.25, 0.5, 1, 2, 3]
            quality: 75
            transformOptions: {
              fit: COVER
            }
            width: 539
          )
        }

        nutrition: imageSharp(resize: {originalName: {eq: "ernaehrungsberatung.jpg"}}) {
          gatsbyImageData(
            aspectRatio: 1
            outputPixelDensities: [0.25, 0.5, 1, 2, 3]
            quality: 75
            transformOptions: {
              fit: COVER
            }
            width: 539
          )
        }

        davidWitton: imageSharp(resize: {originalName: {eq: "david-witton-andre-zimpel-small.jpg"}}) {
          gatsbyImageData(
            quality: 75
            width: 1080
          )
        }

        reviews: allCmsReview(filter: {frontpage: {eq: true}}) {
          edges {
            node {
              summary: excerpt
              name: reviewerName
              image {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 0.9
                    outputPixelDensities: [0.25, 0.5, 1, 2, 3]
                    quality: 75
                    transformOptions: {
      								cropFocus: CENTER,
                      fit: COVER
                    }
                    width: 539
                  )
                }
              }
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <Seo
        title='Startseite'
      />
      <WebsiteHeader
        headline='Personal Training & Schmerztherapie'
        copy={`Im Mittelpunkt unserer Zusammenarbeit stehen ganzheitliches Training und Therapie. Wir behandeln nicht die Symptome sondern die Ursache. Dabei ist die Neuro-Athletik ein wichtiger Bestandteil, denn: Bewegung entsteht im Gehirn.`}
        video={video}
        frontPage={true}
        buttonLink='/kontakt'
        buttonLabel='Kontakt aufnehmen'
      />
      <PagePreview
        headline='Personal Training'
        copy='Erleben Sie ein ganzheitliches effektives Training für Ihre individuellen Ziele, indem wir einzigartig neurozentriertes Training mit funktionellem Training verbinden.'
        image={personalTraining}
        buttonLabel='Mehr erfahren'
        buttonLink='/personal-training'
      />
      <Instagram/>
      <PagePreview
        headline='Schmerztherapie'
        copy='Das Konzept ist ganzheitlich und nachhaltig und soll Ihnen ein Leben ohne Schmerzen, Bewegungseinschränkungen und Medikamente ermöglichen. Die Arbeit an der Ursache und nicht am Symptom, ist maßgeblich für den Erfolg und bildet die Basis der Therapie.'
        image={therapy}
        buttonLabel='Mehr erfahren'
        buttonLink='/schmerztherapie'
        orientation='left'
      />
      <Coach image={davidWitton}/>
      <PagePreview
        headline='Neuro-Athletik'
        copy='Eine optimale körperliche Leistung sowie Beschwerdefreiheit ist nur dann möglich, wenn das Gehirn hochwertige Informationen von Augen, Gleichgewichtssystem und Körper erhält. Mit individuell ausgewählten Übungen können schnell und effektiv die vielfältigsten Probleme gelöst werden.'
        image={neuro}
        buttonLabel='Mehr erfahren'
        buttonLink='/neuro-athletik'
        orientation='left'
      />
      <PagePreview
        headline='Ernährungsberatung'
        copy='Abgestimmt auf Ihre persönlichen Ziele entwickeln wir gemeinsam ein alltagstaugliches Ernährungskonzept. Informationen, praktische Tipps und eine individuelle Betreuung helfen, das Erlernte langfristig umzusetzen – in Familie und Beruf.'
        image={nutrition}
        buttonLabel='Mehr erfahren'
        buttonLink='/ernaehrungsberatung'
      />
      <ReviewCarousel reviews={reviews.edges.map(r => r.node)}/>
    </Layout>
  )
}

export default Index
