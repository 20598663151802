import { coach, container, copy, headline, text, visual } from './index.module.scss'

import Bar from '../bar'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from "react"

const Coach = ({ image }) => {
  return (
    <div className={coach}>
      <div className={container}>
        <div className={text}>
          <div className={headline}>
            <h2>Ihr neuer Trainer</h2>
            <Bar color='var(--brunswick-green-25)'/>
          </div>
          <div className={copy}>
            Ich habe mittlerweile seit über 10 Jahren professionelle Erfahrung in der Verbindung von funktionellem Training mit angewandter Neurologie und Krafttraining. Durch die Teilnahme an vielen Fortbildungen und Seminaren bleibe ich immer auf dem aktuellen Stand und kann so optimal helfen.
          </div>
          <div className={copy}>
            <b>- David Witton</b>
          </div>
        </div>
        <div className={visual}>
          <GatsbyImage image={image.gatsbyImageData} alt='David Witton'/>
        </div>
      </div>
    </div>
  )
}

export default Coach
