// extracted by mini-css-extract-plugin
export var button = "carousel-module--button--iJemO";
export var carousel = "carousel-module--carousel--au5OL";
export var container = "carousel-module--container--YGlJO";
export var fadein = "carousel-module--fadein--yLv6r";
export var image = "carousel-module--image--BZC81";
export var indicator = "carousel-module--indicator--4m5SO";
export var indicatorAcive = "carousel-module--indicatorAcive--ew+CD";
export var indicators = "carousel-module--indicators--U66Ud";
export var name = "carousel-module--name--LGpAZ";
export var slide = "carousel-module--slide--mt4HJ";
export var summary = "carousel-module--summary--MldVx";
export var text = "carousel-module--text--yEhXZ";
export var wrapper = "carousel-module--wrapper--hOKE6";