import { Link } from 'gatsby'
import React from "react"
import { button } from './index.module.scss'

const Button = ({ children, to, onClick }) => {
  const Tag = to ? Link : 'button'
  return (
    <Tag className={button} to={to} onClick={onClick}>
      {children}
    </Tag>
  )
}

export default Button
