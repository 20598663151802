import { container, copyText, left, right, text, title, visual } from './preview.module.scss'

import Button from '../button'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import React from "react"
import TextRenderer from '../text/renderer'

const PagePreview = ({ headline, copy, image, buttonLabel, buttonLink, orientation='right' }) => {
  const className = orientation === 'right' ? right : left
  return (
    <div className={className}>
      <div className={container}>
        <div className={text}>
          <h2 className={title}>{headline}</h2>
          <div className={copyText}><TextRenderer text={copy}/></div>
          <Button to={buttonLink}>{buttonLabel}</Button>
        </div>
        {( image &&
          <Link to={buttonLink} className={visual}>
            <GatsbyImage image={image.gatsbyImageData} alt={headline}/>
          </Link>
        )}
      </div>
    </div>
  )
};

export default PagePreview
