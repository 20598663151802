import Carousel, { CarouselItem } from "../carousel";
import { button, carousel, container, icon, image, indicator, indicatorAcive, indicators, name, slide, summary, text, wrapper } from './carousel.module.scss'

import Button from '../button'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

const ReviewCarousel = ({ reviews=[] }) => {
  const slides = reviews.map((review, index) => (
    <CarouselItem key={index} className={slide}>
      <div className={image}>
        <GatsbyImage image={review?.image?.childImageSharp?.gatsbyImageData} alt={review.name}/>
      </div>
      <div className={text}>
        <div className={icon}>
          <svg width="40" height="35" viewBox="0 0 40 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M35 15H30V13.125C30 10.7109 31.9609 8.75 34.375 8.75H35.625C37.3516 8.75 38.75 7.35156 38.75 5.625V3.125C38.75 1.39844 37.3516 0 35.625 0H34.375C27.1406 0 21.25 5.89063 21.25 13.125V30C21.25 32.7578 23.4922 35 26.25 35H35C37.7578 35 40 32.7578 40 30V20C40 17.2422 37.7578 15 35 15ZM37.5 30C37.5 31.3828 36.3828 32.5 35 32.5H26.25C24.8672 32.5 23.75 31.3828 23.75 30V13.125C23.75 7.25781 28.5078 2.5 34.375 2.5H35.625C35.9688 2.5 36.25 2.78125 36.25 3.125V5.625C36.25 5.96875 35.9688 6.25 35.625 6.25H34.375C30.5781 6.25 27.5 9.32813 27.5 13.125V17.5H35C36.3828 17.5 37.5 18.6172 37.5 20V30ZM13.75 15H8.75V13.125C8.75 10.7109 10.7109 8.75 13.125 8.75H14.375C16.1016 8.75 17.5 7.35156 17.5 5.625V3.125C17.5 1.39844 16.1016 0 14.375 0H13.125C5.89063 0 0 5.89063 0 13.125V30C0 32.7578 2.24219 35 5 35H13.75C16.5078 35 18.75 32.7578 18.75 30V20C18.75 17.2422 16.5078 15 13.75 15ZM16.25 30C16.25 31.3828 15.1328 32.5 13.75 32.5H5C3.61719 32.5 2.5 31.3828 2.5 30V13.125C2.5 7.25781 7.25781 2.5 13.125 2.5H14.375C14.7188 2.5 15 2.78125 15 3.125V5.625C15 5.96875 14.7188 6.25 14.375 6.25H13.125C9.32813 6.25 6.25 9.32813 6.25 13.125V17.5H13.75C15.1328 17.5 16.25 18.6172 16.25 20V30Z" fill="#395B50"/>
          </svg>
        </div>
        <div className={summary}>
          {review.summary}
        </div>
        <div className={name}>
          - {review.name}
        </div>
      </div>
    </CarouselItem>
  ))

  return (
    <div className={wrapper}>
      <div className={container}>
        <div className={carousel}>
          <Carousel hideButtons={true} indicatorsClassName={indicators} indicatorClassName={indicator} indicatorActiveClassName={indicatorAcive}>
            {slides}
          </Carousel>
        </div>
        <div className={button}>
          <Button to="/bewertungen">Alle Bewertungen</Button>
        </div>
      </div>
    </div>
  )
}

export default ReviewCarousel
